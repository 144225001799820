// File: frontend/src/pages/SuperAdmin/components/EnterpriseManagement/index.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import EnterpriseList from './EnterpriseList';
import EnterpriseDetail from './EnterpriseDetail';
import EnterpriseCreate from './EnterpriseCreate';
import EnterpriseEdit from './EnterpriseEdit';
import styles from './EnterpriseManagement.module.css';

const EnterpriseManagement = ({ updateBreadcrumbs }) => {
  const { t } = useLanguage();
  const [view, setView] = useState('list');
  const [selectedEnterpriseId, setSelectedEnterpriseId] = useState(null);

  // Handle view changes
  const handleViewChange = (newView, enterpriseId = null) => {
    setView(newView);
    setSelectedEnterpriseId(enterpriseId);
    
    // Update breadcrumbs based on view
    const baseBreadcrumbs = [
      { label: 'Dashboard', path: '/super-admin-panel' },
      { label: 'Enterprise Management', path: '/super-admin-panel/enterprises' }
    ];
    
    switch (newView) {
      case 'detail':
        updateBreadcrumbs([
          ...baseBreadcrumbs,
          { label: 'Enterprise Details', path: `/super-admin-panel/enterprises/${enterpriseId}` }
        ]);
        break;
      case 'create':
        updateBreadcrumbs([
          ...baseBreadcrumbs,
          { label: 'Create Enterprise', path: '/super-admin-panel/enterprises/create' }
        ]);
        break;
      case 'edit':
        updateBreadcrumbs([
          ...baseBreadcrumbs,
          { 
            label: 'Enterprise Details', 
            path: `/super-admin-panel/enterprises/${enterpriseId}` 
          },
          { 
            label: 'Edit Enterprise', 
            path: `/super-admin-panel/enterprises/${enterpriseId}/edit` 
          }
        ]);
        break;
      default:
        updateBreadcrumbs(baseBreadcrumbs);
    }
  };

  // Render the appropriate view
  const renderView = () => {
    switch (view) {
      case 'detail':
        return (
          <EnterpriseDetail 
            enterpriseId={selectedEnterpriseId} 
            onBack={() => handleViewChange('list')}
            onEdit={() => handleViewChange('edit', selectedEnterpriseId)}
          />
        );
      case 'create':
        return (
          <EnterpriseCreate 
            onBack={() => handleViewChange('list')}
            onSuccess={(enterpriseId) => handleViewChange('detail', enterpriseId)}
          />
        );
      case 'edit':
        return (
          <EnterpriseEdit 
            enterpriseId={selectedEnterpriseId}
            onBack={() => handleViewChange('detail', selectedEnterpriseId)}
            onSuccess={() => handleViewChange('detail', selectedEnterpriseId)}
          />
        );
      default:
        return (
          <EnterpriseList 
            onViewDetail={(enterpriseId) => handleViewChange('detail', enterpriseId)}
            onCreateNew={() => handleViewChange('create')}
          />
        );
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        {view === 'list' && t('enterprise_management')}
        {view === 'detail' && t('enterprise_details')}
        {view === 'create' && t('create_enterprise')}
        {view === 'edit' && t('edit_enterprise')}
      </h1>
      
      {renderView()}
    </div>
  );
};

export default EnterpriseManagement;