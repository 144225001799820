// File: frontend/src/pages/SuperAdmin/components/UserManagement/index.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import UserList from './UserList';
import UserDetail from './UserDetail';
import UserVerification from './UserVerification';
import styles from './UserManagement.module.css';

const UserManagement = ({ updateBreadcrumbs }) => {
  const { t } = useLanguage();
  const [view, setView] = useState('list');
  const [selectedUserId, setSelectedUserId] = useState(null);

  // Handle view changes
  const handleViewChange = (newView, userId = null) => {
    setView(newView);
    setSelectedUserId(userId);
    
    // Update breadcrumbs based on view
    const baseBreadcrumbs = [
      { label: 'Dashboard', path: '/super-admin-panel' },
      { label: 'User Management', path: '/super-admin-panel/users' }
    ];
    
    switch (newView) {
      case 'detail':
        updateBreadcrumbs([
          ...baseBreadcrumbs,
          { label: 'User Details', path: `/super-admin-panel/users/${userId}` }
        ]);
        break;
      case 'verification':
        updateBreadcrumbs([
          ...baseBreadcrumbs,
          { label: 'User Verification', path: '/super-admin-panel/users/verification' }
        ]);
        break;
      default:
        updateBreadcrumbs(baseBreadcrumbs);
    }
  };

  // Render the appropriate view
  const renderView = () => {
    switch (view) {
      case 'detail':
        return (
          <UserDetail 
            userId={selectedUserId} 
            onBack={() => handleViewChange('list')}
          />
        );
      case 'verification':
        return (
          <UserVerification 
            onBack={() => handleViewChange('list')}
            onViewUser={(userId) => handleViewChange('detail', userId)}
          />
        );
      default:
        return (
          <UserList 
            onViewDetail={(userId) => handleViewChange('detail', userId)}
            onViewVerification={() => handleViewChange('verification')}
          />
        );
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        {view === 'list' && t('user_management')}
        {view === 'detail' && t('user_details')}
        {view === 'verification' && t('user_verification')}
      </h1>
      
      {renderView()}
    </div>
  );
};

export default UserManagement;