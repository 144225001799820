// File: frontend/src/pages/SuperAdmin/components/PitchAccessManager/index.js

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faSearch, faExclamationCircle, faCheckCircle, faPlusCircle, 
  faExternalLinkAlt, faBan, faCopy, faUsersSlash 
} from '@fortawesome/free-solid-svg-icons';
import { useLanguage } from '../../../../contexts/LanguageContext';
import { useUser } from '../../../../contexts/UserContext';
import pitchService from '../../../../services/superAdmin/pitchService';
import styles from './PitchAccessManager.module.css';

const PitchAccessManager = ({ updateBreadcrumbs }) => {
  const { t } = useLanguage();
  const { user } = useUser();
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [selectedDeck, setSelectedDeck] = useState('investor-series-a');
  const [enterpriseId, setEnterpriseId] = useState('');
  const [enterpriseName, setEnterpriseName] = useState('');
  const [enterpriseDomain, setEnterpriseDomain] = useState('');
  const [leadPotential, setLeadPotential] = useState('Unknown');
  const [accessList, setAccessList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredAccessList, setFilteredAccessList] = useState([]);
  const [durationDays, setDurationDays] = useState(50);

  const decks = [
    { id: 'investor-seed', name: 'Investor Seed Pitch' },
    { id: 'investor-series-a', name: 'Investor Series A Pitch' },
    { id: 'customer-insurers', name: 'Customer Insurers Pitch' },
    { id: 'customer-brokers', name: 'Customer Brokers Pitch' }
  ];

  const leadPotentialOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
    { value: 'Maybe', label: 'Maybe' },
    { value: 'Unknown', label: 'Unknown' }
  ];

  const getBaseUrl = () => {
    if (process.env.REACT_APP_APP_ENV === 'prod') {
      return 'https://allegory.app';
    } else if (process.env.REACT_APP_APP_ENV === 'dev') {
      return 'https://dev.allegory.app';
    }
    return 'http://localhost:3001';
  };

  // Update breadcrumbs when component mounts
  useEffect(() => {
    updateBreadcrumbs([
      { label: 'Dashboard', path: '/super-admin-panel' },
      { label: 'Pitch Access Manager', path: '/super-admin-panel/pitch-access' }
    ]);
  }, [updateBreadcrumbs]);

  // Auto-fill domain when email changes
  useEffect(() => {
    if (email && email.includes('@')) {
      const domain = email.split('@')[1];
      setEnterpriseDomain(domain);
    }
  }, [email]);

  // Load access list
  useEffect(() => {
    const fetchAccessList = async () => {
      setIsLoading(true);
      setError(null);
      
      try {
        const response = await pitchService.getAccessList(selectedDeck);
        
        if (response.success) {
          setAccessList(response.records || []);
          setFilteredAccessList(response.records || []);
        } else {
          throw new Error(response.message || 'Failed to load access list');
        }
      } catch (err) {
        console.error('Error fetching access list:', err);
        setError('Failed to load access list. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchAccessList();
  }, [selectedDeck]);

  // Filter access list when search term changes
  useEffect(() => {
    if (accessList.length > 0) {
      if (searchTerm) {
        const searchLower = searchTerm.toLowerCase();
        const filtered = accessList.filter(access => 
          access.email.toLowerCase().includes(searchLower) ||
          access.access_status.toLowerCase().includes(searchLower) ||
          (access.enterprise_name && access.enterprise_name.toLowerCase().includes(searchLower)) ||
          (access.first_name && access.first_name.toLowerCase().includes(searchLower)) ||
          (access.last_name && access.last_name.toLowerCase().includes(searchLower))
        );
        setFilteredAccessList(filtered);
      } else {
        setFilteredAccessList(accessList);
      }
    }
  }, [searchTerm, accessList]);

  // Add access
  const handleAddAccess = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setSuccess(null);
    
    try {
      // Validate email
      if (!email || !/\S+@\S+\.\S+/.test(email)) {
        throw new Error('Please enter a valid email address');
      }
      
      // Validate enterprise ID
      if (!enterpriseId) {
        throw new Error('Please enter an Enterprise ID');
      }
      
      const response = await pitchService.grantAccess(
        email, 
        selectedDeck, 
        durationDays, 
        enterpriseId, 
        {
          enterpriseName,
          enterpriseDomain,
          firstName,
          lastName,
          leadPotential
        }
      );
      
      if (response.success) {
        // Refresh the access list
        const listResponse = await pitchService.getAccessList(selectedDeck);
        
        if (listResponse.success) {
          setAccessList(listResponse.records || []);
          setFilteredAccessList(listResponse.records || []);
        }
        
        setSuccess(`Access granted to ${email} for ${durationDays} days`);
        setEmail('');
        setFirstName('');
        setLastName('');
        // Don't clear enterpriseId to make it easier to add multiple users for the same enterprise
      } else {
        throw new Error(response.message || 'Failed to grant access');
      }
    } catch (err) {
      console.error('Error adding access:', err);
      setError(err.message || 'Failed to grant access. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Revoke access
  const handleRevokeAccess = async (accessId) => {
    if (!window.confirm('Are you sure you want to revoke access for this user?')) return;
    
    setIsLoading(true);
    setError(null);
    setSuccess(null);
    
    try {
      const response = await pitchService.revokeAccess(accessId);
      
      if (response.success) {
        // Update local state
        const updatedAccessList = accessList.map(access => 
          access.access_id === accessId 
            ? { ...access, access_status: 'revoked', revoked_at: new Date().toISOString() }
            : access
        );
        
        setAccessList(updatedAccessList);
        setFilteredAccessList(updatedAccessList.filter(access => 
          !searchTerm || 
          access.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          access.access_status.toLowerCase().includes(searchTerm.toLowerCase())
        ));
        
        setSuccess(`Access revoked successfully`);
      } else {
        throw new Error(response.message || 'Failed to revoke access');
      }
    } catch (err) {
      console.error('Error revoking access:', err);
      setError(err.message || 'Failed to revoke access. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString) => {
    return pitchService.formatDate(dateString);
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'active':
        return styles.statusActive;
      case 'expired':
        return styles.statusExpired;
      case 'revoked':
        return styles.statusRevoked;
      default:
        return '';
    }
  };

  const isExpired = (expiryDate) => {
    return pitchService.isExpired(expiryDate);
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Pitch Deck Access Manager</h1>
      
      <div className={styles.controls}>
        <div className={styles.deckSelector}>
          <label htmlFor="deckSelect" className={styles.label}>Select Pitch Deck:</label>
          <select
            id="deckSelect"
            className={styles.select}
            value={selectedDeck}
            onChange={(e) => setSelectedDeck(e.target.value)}
            disabled={isLoading}
          >
            {decks.map(deck => (
              <option key={deck.id} value={deck.id}>{deck.name}</option>
            ))}
          </select>
        </div>
        
        <div className={styles.searchContainer}>
          <input
            type="text"
            className={styles.searchInput}
            placeholder="Search by email, name, or status..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <FontAwesomeIcon icon={faSearch} className={styles.searchIcon} />
        </div>
      </div>
      
      {error && (
        <div className={styles.error}>
          <FontAwesomeIcon icon={faExclamationCircle} />
          <span>{error}</span>
        </div>
      )}
      
      {success && (
        <div className={styles.success}>
          <FontAwesomeIcon icon={faCheckCircle} />
          <span>{success}</span>
        </div>
      )}
      
      <div className={styles.addAccessSection}>
        <h2 className={styles.sectionTitle}>Grant New Access</h2>
        <form onSubmit={handleAddAccess} className={styles.addAccessForm}>
          {/* First row */}
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label htmlFor="email" className={styles.label}>Email Address:</label>
              <input
                type="email"
                id="email"
                className={styles.input}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email address"
                disabled={isLoading}
                required
              />
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="firstName" className={styles.label}>First Name:</label>
              <input
                type="text"
                id="firstName"
                className={styles.input}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="Enter first name"
                disabled={isLoading}
              />
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="lastName" className={styles.label}>Last Name:</label>
              <input
                type="text"
                id="lastName"
                className={styles.input}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Enter last name"
                disabled={isLoading}
              />
            </div>
          </div>
          
          {/* Second row */}
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label htmlFor="enterpriseId" className={styles.label}>Enterprise ID:</label>
              <input
                type="text"
                id="enterpriseId"
                className={styles.input}
                value={enterpriseId}
                onChange={(e) => setEnterpriseId(e.target.value)}
                placeholder="e.g., dc76dbb3-0da1-4ff5-992e-f97ed77e93f9"
                disabled={isLoading}
                required
              />
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="enterpriseName" className={styles.label}>Enterprise Name:</label>
              <input
                type="text"
                id="enterpriseName"
                className={styles.input}
                value={enterpriseName}
                onChange={(e) => setEnterpriseName(e.target.value)}
                placeholder="e.g., Acme Corp"
                disabled={isLoading}
              />
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="enterpriseDomain" className={styles.label}>Enterprise Domain:</label>
              <input
                type="text"
                id="enterpriseDomain"
                className={styles.input}
                value={enterpriseDomain}
                onChange={(e) => setEnterpriseDomain(e.target.value)}
                placeholder="e.g., acme.com"
                disabled={isLoading}
              />
            </div>
          </div>
          
          {/* Third row */}
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label htmlFor="leadPotential" className={styles.label}>Lead Potential:</label>
              <select
                id="leadPotential"
                className={styles.select}
                value={leadPotential}
                onChange={(e) => setLeadPotential(e.target.value)}
                disabled={isLoading}
              >
                {leadPotentialOptions.map(option => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="durationDays" className={styles.label}>Duration (days):</label>
              <input
                type="number"
                id="durationDays"
                className={styles.input}
                value={durationDays}
                onChange={(e) => setDurationDays(parseInt(e.target.value, 10))}
                min="1"
                max="365"
                disabled={isLoading}
                required
              />
            </div>
            
            <div className={styles.formGroup + ' ' + styles.buttonGroup}>
              <button
                type="submit"
                className={styles.addButton}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <div className={styles.spinner}></div>
                    <span>Processing...</span>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faPlusCircle} />
                    <span>Grant Access</span>
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      
      <div className={styles.accessListSection}>
        <h2 className={styles.sectionTitle}>Access List</h2>
        
        {isLoading && filteredAccessList.length === 0 ? (
          <div className={styles.loading}>
            <div className={styles.spinner}></div>
            <p>Loading access list...</p>
          </div>
        ) : filteredAccessList.length > 0 ? (
          <div className={styles.tableContainer}>
            <table className={styles.accessTable}>
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Name</th>
                  <th>Enterprise</th>
                  <th>Domain</th>
                  <th>Lead Potential</th>
                  <th>Status</th>
                  <th>Granted</th>
                  <th>Expires</th>
                  <th>Views</th>
                  <th>Pitch Link</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredAccessList.map(access => {
                  const expired = isExpired(access.expires_at);
                  const status = expired && access.access_status === 'active' ? 'expired' : access.access_status;
                  const fullName = [access.first_name, access.last_name].filter(Boolean).join(' ') || 'N/A';
                  const pitchLink = access.pitch_link || 
                    (access.enterprise_hash && access.pitch_id ? 
                      `${getBaseUrl()}/${access.enterprise_hash}-${access.pitch_id}` : '#');
                  
                  return (
                    <tr key={access.access_id}>
                      <td>{access.email}</td>
                      <td>{fullName}</td>
                      <td>{access.enterprise_name || 'N/A'}</td>
                      <td>{access.enterprise_domain_name || 'N/A'}</td>
                      <td>{access.lead_potential || 'Unknown'}</td>
                      <td>
                        <span className={`${styles.statusBadge} ${getStatusBadgeClass(status)}`}>
                          {status}
                        </span>
                      </td>
                      <td>{formatDate(access.granted_at)}</td>
                      <td>{formatDate(access.expires_at)}</td>
                      <td>{access.access_count}</td>
                      <td>
                        {access.enterprise_id ? (
                          <a 
                            href={pitchLink} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className={styles.pitchLink}
                          >
                            <FontAwesomeIcon icon={faExternalLinkAlt} /> View
                          </a>
                        ) : 'N/A'}
                      </td>
                      <td>
                        <div className={styles.actions}>
                          {status === 'active' && (
                            <button
                              className={styles.revokeButton}
                              onClick={() => handleRevokeAccess(access.access_id)}
                              disabled={isLoading}
                              title="Revoke Access"
                            >
                              <FontAwesomeIcon icon={faBan} />
                            </button>
                          )}
                          <button
                            className={styles.copyButton}
                            onClick={() => {
                              navigator.clipboard.writeText(pitchLink);
                              alert('Pitch link copied to clipboard!');
                            }}
                            disabled={!access.enterprise_id}
                            title="Copy Link"
                          >
                            <FontAwesomeIcon icon={faCopy} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className={styles.emptyState}>
            <FontAwesomeIcon icon={faUsersSlash} />
            <p>No access records found{searchTerm ? ' matching your search' : ''}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PitchAccessManager;