// File: frontend/src/pages/SuperAdmin/components/CommunicationManagement/index.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import EmailTemplates from './EmailTemplates';
import SmsTemplates from './SmsTemplates';
import CommunicationLogs from './CommunicationLogs';
import styles from './CommunicationManagement.module.css';

const CommunicationManagement = ({ updateBreadcrumbs }) => {
  const { t } = useLanguage();
  const [view, setView] = useState('email');
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(false);

  // Handle view changes
  const handleViewChange = (newView, templateId = null, mode = 'view') => {
    setView(newView);
    setSelectedTemplateId(templateId);
    setIsEditMode(mode === 'edit');
    setIsCreateMode(mode === 'create');
    
    // Update breadcrumbs based on view
    const baseBreadcrumbs = [
      { label: 'Dashboard', path: '/super-admin-panel' },
      { label: 'Communication Management', path: '/super-admin-panel/communications' }
    ];
    
    switch (newView) {
      case 'email':
        if (mode === 'view' && templateId) {
          updateBreadcrumbs([
            ...baseBreadcrumbs,
            { label: 'Email Templates', path: '/super-admin-panel/communications/email' },
            { label: 'Template Details', path: `/super-admin-panel/communications/email/${templateId}` }
          ]);
        } else if (mode === 'edit' && templateId) {
          updateBreadcrumbs([
            ...baseBreadcrumbs,
            { label: 'Email Templates', path: '/super-admin-panel/communications/email' },
            { label: 'Edit Template', path: `/super-admin-panel/communications/email/${templateId}/edit` }
          ]);
        } else if (mode === 'create') {
          updateBreadcrumbs([
            ...baseBreadcrumbs,
            { label: 'Email Templates', path: '/super-admin-panel/communications/email' },
            { label: 'Create Template', path: '/super-admin-panel/communications/email/create' }
          ]);
        } else {
          updateBreadcrumbs([
            ...baseBreadcrumbs,
            { label: 'Email Templates', path: '/super-admin-panel/communications/email' }
          ]);
        }
        break;
      case 'sms':
        if (mode === 'view' && templateId) {
          updateBreadcrumbs([
            ...baseBreadcrumbs,
            { label: 'SMS Templates', path: '/super-admin-panel/communications/sms' },
            { label: 'Template Details', path: `/super-admin-panel/communications/sms/${templateId}` }
          ]);
        } else if (mode === 'edit' && templateId) {
          updateBreadcrumbs([
            ...baseBreadcrumbs,
            { label: 'SMS Templates', path: '/super-admin-panel/communications/sms' },
            { label: 'Edit Template', path: `/super-admin-panel/communications/sms/${templateId}/edit` }
          ]);
        } else if (mode === 'create') {
          updateBreadcrumbs([
            ...baseBreadcrumbs,
            { label: 'SMS Templates', path: '/super-admin-panel/communications/sms' },
            { label: 'Create Template', path: '/super-admin-panel/communications/sms/create' }
          ]);
        } else {
          updateBreadcrumbs([
            ...baseBreadcrumbs,
            { label: 'SMS Templates', path: '/super-admin-panel/communications/sms' }
          ]);
        }
        break;
      case 'logs':
        updateBreadcrumbs([
          ...baseBreadcrumbs,
          { label: 'Communication Logs', path: '/super-admin-panel/communications/logs' }
        ]);
        break;
      default:
        updateBreadcrumbs(baseBreadcrumbs);
    }
  };

  // Render the appropriate view
  const renderView = () => {
    switch (view) {
      case 'email':
        return (
          <EmailTemplates 
            templateId={selectedTemplateId}
            isEditMode={isEditMode}
            isCreateMode={isCreateMode}
            onViewChange={handleViewChange}
          />
        );
      case 'sms':
        return (
          <SmsTemplates 
            templateId={selectedTemplateId}
            isEditMode={isEditMode}
            isCreateMode={isCreateMode}
            onViewChange={handleViewChange}
          />
        );
      case 'logs':
        return (
          <CommunicationLogs 
            onViewChange={handleViewChange}
          />
        );
      default:
        return (
          <EmailTemplates 
            templateId={selectedTemplateId}
            isEditMode={isEditMode}
            isCreateMode={isCreateMode}
            onViewChange={handleViewChange}
          />
        );
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        {t('communication_management')}
      </h1>
      
      <div className={styles.tabs}>
        <button 
          className={`${styles.tab} ${view === 'email' ? styles.activeTab : ''}`}
          onClick={() => handleViewChange('email')}
        >
          <i className="fas fa-envelope"></i>
          <span>{t('email_templates')}</span>
        </button>
        <button 
          className={`${styles.tab} ${view === 'sms' ? styles.activeTab : ''}`}
          onClick={() => handleViewChange('sms')}
        >
          <i className="fas fa-sms"></i>
          <span>{t('sms_templates')}</span>
        </button>
        <button 
          className={`${styles.tab} ${view === 'logs' ? styles.activeTab : ''}`}
          onClick={() => handleViewChange('logs')}
        >
          <i className="fas fa-history"></i>
          <span>{t('communication_logs')}</span>
        </button>
      </div>
      
      <div className={styles.content}>
        {renderView()}
      </div>
    </div>
  );
};

export default CommunicationManagement;