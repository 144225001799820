// File: frontend/src/pages/InvestorPitch/components/slides/DataRoomSlide/DataRoomSlide.js

import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import styles from './DataRoomSlide.module.css';

const DataRoomSlide = () => {
  const { t } = useLanguage();
  const [activeCategory, setActiveCategory] = useState('technical');
  
  const baseS3Url = 'https://allegory-public.s3.ca-central-1.amazonaws.com/pitch/adr-series-a-2ab3cce4-ca9c-4498-bfc4-b2e8d1857826/';
  
  const categories = [
    { id: 'technical', label: t('investor_pitch.data_room.categories.technical', 'Technical Documentation') },
    { id: 'corporate', label: t('investor_pitch.data_room.categories.corporate', 'Corporate Documents') },
    { id: 'regulatory', label: t('investor_pitch.data_room.categories.regulatory', 'Regulatory Materials') },
    { id: 'market', label: t('investor_pitch.data_room.categories.market', 'Market & Business') },
    { id: 'team', label: t('investor_pitch.data_room.categories.team', 'Team') },
    { id: 'financial', label: t('investor_pitch.data_room.categories.financial', 'Financial Materials') },
  ];
  
  const documents = {
    corporate: [
      {
        title: 'Certificate of Incorporation',
        description: 'Official incorporation document for Allegory Technology Inc., registered in Canada (2019)',
        filename: 'allegory_certificate_of_incorporation.pdf'
      },
      {
        title: 'Articles of Incorporation',
        description: 'Complete articles of incorporation detailing the company structure and governance',
        filename: 'allegory_complete_articles_of_incorporation.pdf'
      },
      {
        title: 'Corporate By-Laws',
        description: 'By-laws governing the operations of Allegory Technology Inc.',
        filename: 'allegory_technology_inc_by_laws.pdf'
      },
      {
        title: 'First Director Resolution',
        description: 'Initial resolutions passed by the board of directors',
        filename: 'allegory_first_director_resolution.pdf'
      },
      {
        title: 'First Shareholder Resolution',
        description: 'Initial resolutions passed by the shareholders',
        filename: 'allegory_first_shareholder_resolution.pdf'
      },
      {
        title: 'Initial Share Subscription',
        description: 'Documentation of initial share issuance',
        filename: 'allegory_initial_subscription_for_shares.pdf'
      },
      {
        title: 'Annual Return 2021',
        description: 'Annual corporate filing for 2021',
        filename: 'allegory_technology_inc_annual_return_2021.pdf'
      },
      {
        title: 'Annual Return 2022',
        description: 'Annual corporate filing for 2022',
        filename: 'allegory_technology_inc_annual_return_2022.pdf'
      },
      {
        title: 'Annual Return 2023',
        description: 'Annual corporate filing for 2023',
        filename: 'allegory_technology_inc_annual_return_2023.pdf'
      }
    ],
    financial: [
      {
        title: 'Financial Model & Projections',
        description: '5-year financial projections with detailed assumptions and scenarios',
        filename: 'allegory_financial_model.pdf',
        comingSoon: true
      },
      {
        title: 'Burn Rate Analysis',
        description: 'Current burn rate and runway analysis with Series A funding allocation timeline',
        filename: 'allegory_burn_rate_analysis.pdf',
        comingSoon: true
      },
      {
        title: 'Valuation Model',
        description: 'Detailed breakdown of company valuation methodology',
        filename: 'allegory_valuation_model.pdf',
        comingSoon: true
      }
    ],
    technical: [
      {
        title: 'AI-1: 40 Context-Aware Agents',
        description: 'Detailed documentation of our AI agent system and capabilities',
        filename: 'allegory_overview_of_ai_agents.pdf'
      },
      {
        title: 'Technical Architecture',
        description: 'Comprehensive overview of Allegory\'s technical infrastructure and systems',
        filename: 'allegory_technical_architecture.pdf'
      },
      {
        title: 'Security & Compliance',
        description: 'Security protocols, data protection measures, and compliance frameworks',
        filename: 'allegory_security_and_compliance.pdf'
      },
      {
        title: 'AI-2 Development Roadmap',
        description: 'Strategic roadmap for the next generation of Allegory Intelligence',
        filename: 'allegory_ai2_roadmap.pdf',
        comingSoon: true
      },
    ],
    regulatory: [
      {
        title: 'Process Control & Dispute Handling',
        description: 'Frameworks for operational control and customer dispute resolution',
        filename: 'allegory_process_control_and_dispute_handling_v3.pdf'
      },
      {
        title: 'Regulatory Strategy',
        description: 'Comprehensive strategy for insurance regulatory compliance across multiple jurisdictions',
        filename: 'allegory_regulatory_strategy.pdf',
        comingSoon: true
      },
      {
        title: 'Licensing Status',
        description: 'Current status of insurance licenses and applications across territories',
        filename: 'allegory_licensing_status.pdf',
        comingSoon: true
      },
      {
        title: 'Regulatory Correspondence',
        description: 'Documentation of key communications with insurance regulators',
        filename: 'allegory_regulatory_correspondence.pdf',
        comingSoon: true
      },
    ],
    market: [
      {
        title: 'Customer Acquisition Strategy',
        description: 'Detailed go-to-market and customer acquisition strategy',
        filename: 'allegory_customer_acquisition.pdf',
        comingSoon: true
      },
      {
        title: 'Competitive Analysis',
        description: 'In-depth analysis of competitive landscape across insurtech and traditional insurance',
        filename: 'allegory_competitive_analysis.pdf',
        comingSoon: true
      },
      {
        title: 'Partnership Strategy',
        description: 'Current and planned strategic partnerships and distribution channels',
        filename: 'allegory_partnership_strategy.pdf',
        comingSoon: true
      },
      {
        title: 'Pricing & Underwriting Guidelines',
        description: 'Overview of pricing methodology and underwriting approach',
        filename: 'allegory_pricing_underwriting.pdf',
        comingSoon: true
      }
    ],
    team: [
      {
        title: 'Organization Chart',
        description: 'Current organizational structure and reporting relationships',
        filename: 'allegory_organization_chart.pdf'
      },
      {
        title: 'Hiring Plan',
        description: 'Detailed hiring roadmap for the next 24 months',
        filename: 'allegory_hiring_plan.pdf'
      },
      {
        title: 'Board Structure & Governance',
        description: 'Current and proposed board structure, including committees and governance framework',
        filename: 'allegory_board_structure_and_governance.pdf'
      },
      {
        title: 'Executive Bios',
        description: 'Detailed biographies of key executives and technical leaders',
        filename: 'allegory_executive_bios.pdf',
        comingSoon: true
      }
    ]
  };

  const handleCategoryClick = (categoryId) => {
    setActiveCategory(categoryId);
  };

  const handleDocumentClick = (filename) => {
    if (!filename.includes('coming_soon')) {
      window.open(baseS3Url + filename, '_blank');
    }
  };

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h2 className={styles.title}>
          {t('investor_pitch.data_room.title', 'Appendix: Investor Data Room')}
        </h2>
        <p className={styles.subtitle}>
          {t('investor_pitch.data_room.subtitle', 'Comprehensive due diligence materials for Series A investors')}
        </p>
      </motion.div>

      <motion.div 
        className={styles.content}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <div className={styles.corporateNote}>
          <div className={styles.noteIcon}>ℹ️</div>
          <div className={styles.noteContent}>
            <h4>{t('investor_pitch.data_room.corporate_note.title', 'Corporate Structure Note')}</h4>
            <p>
              {t('investor_pitch.data_room.corporate_note.text', 'Allegory Technology Inc. was founded in 2019 and is currently incorporated in Canada. With this Series A investment, we plan to establish Allegory Holdings in consultation with our lead investor to optimize the corporate structure. Allegory Technology Inc. will become the Canadian subsidiary of this holding company. We welcome investor guidance on the optimal jurisdiction and structure.')}
            </p>
          </div>
        </div>

        <div className={styles.dataRoomContainer}>
          <div className={styles.categoriesContainer}>
            {categories.map(category => (
              <button
                key={category.id}
                className={`${styles.categoryButton} ${activeCategory === category.id ? styles.activeCategory : ''}`}
                onClick={() => handleCategoryClick(category.id)}
              >
                {category.label}
              </button>
            ))}
          </div>
          
          <div className={styles.documentsContainer}>
            <h3 className={styles.categoryTitle}>
              {categories.find(cat => cat.id === activeCategory)?.label}
            </h3>
            
            <div className={styles.documentsList}>
              {documents[activeCategory].map((doc, index) => (
                <motion.div
                  key={index}
                  className={`${styles.documentCard} ${doc.comingSoon ? styles.comingSoon : ''}`}
                  variants={ANIMATION_VARIANTS.stagger.item}
                  whileHover={!doc.comingSoon ? { scale: 1.02 } : {}}
                  onClick={() => !doc.comingSoon && handleDocumentClick(doc.filename)}
                >
                  <div className={styles.documentIcon}>
                    {doc.comingSoon ? '🔒' : '📄'}
                  </div>
                  <div className={styles.documentInfo}>
                    <h4 className={styles.documentTitle}>
                      {doc.title}
                      {doc.comingSoon && <span className={styles.comingSoonTag}>{t('investor_pitch.data_room.coming_soon', 'Coming Soon')}</span>}
                    </h4>
                    <p className={styles.documentDescription}>{doc.description}</p>
                  </div>
                  {!doc.comingSoon && (
                    <div className={styles.downloadButton}>
                      {t('investor_pitch.data_room.view', 'View')}
                    </div>
                  )}
                </motion.div>
              ))}
            </div>
          </div>
        </div>
        
        <div className={styles.accessNote}>
          <p>
            {t('investor_pitch.data_room.access_note', 'For full data room access, please contact')} <a href="mailto:investors@allegory.app" className={styles.emailLink}>investors@allegory.app</a>
          </p>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default DataRoomSlide;